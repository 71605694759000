/* You can add global styles to this file, and also import other style files */

/*@import "../node_modules/@angular/material/prebuilt-themes/deeppurple-amber.css";*/
@import "amt-theme.css";
@import "app/components/day-view/day-view.component.css";
/* @import "assets/styles/custom-theme1.css"; */
@import "assets/styles/custom-theme2.css";
/* @import "assets/styles/custom-theme3.css"; */
/* @import "~@fortawesome/fontawesome-free/css/all.css"; */
/* @import '~handsontable/dist/handsontable.full.css'; */
@import "~@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";

/* @import "../node_modules/@angular/material/prebuilt-themes/purple-green.css"; */
/* @import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css"; */
:root {
    --accent-font: 'Nunito';
}
.logoText {
    color: unset !important;
    color: var(--logo-text-color) !important;
}
html, body { height: 100%; }
body {
    font-weight: 300; margin: 0;
    font-family: Roboto,Helvetica,Arial,sans-serif !important;
}

.mat-grid-tile {
    overflow: visible !important;
}

.menu-toolbar-mat {
    background-color: var(--toolbar-background) !important;
    /* box-shadow: 0 16px 38px -12px rgb(0 0 0 / 56%), 0 4px 25px 0 rgb(0 0 0 / 12%), 0 8px 10px -5px rgb(0 0 0 / 20%); */
    color: var(--toolbar-text-color);
}


mat-card-subtitle.mat-card-subtitle.card-category {
    color: rgba(255,255,255,.62);
}

.card-header.card-header-danger {
    color: #3c4858;
    background: linear-gradient(60deg,#ef5350,#e53935);
    box-shadow: 0 4px 20px 0 rgb(0 0 0 / 14%), 0 7px 10px -5px rgb(244 67 54 / 40%);
    border-radius: 3px !important;
    margin-top: -30px !important;
    padding: 15px;
    border-bottom: none;
    color: #fff;
}

.mat-card {
    border: 0;
    margin-bottom: 0px;
    margin-top: 30px;
    border-radius: 6px;
    color: #333;
    background: var(--card-background-color);
    width: 100%;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    font-size: .875rem;
}

.mat-card-title {
    font-size: 1.125rem;
    line-height: 1.4em;
    font-weight: 400;
    font-family: var(--accent-font)
}

.card .card-header-danger .card-icon, .card .card-header-danger .card-text, .card .card-header-danger:not(.card-header-icon):not(.card-header-text), .card.bg-danger, .card.card-rotate.bg-danger .back, .card.card-rotate.bg-danger .front {
    background: linear-gradient(60deg,#ef5350,#e53935) !important;
}

.card [class*=card-header-]:not(.card-header-icon):not(.card-header-text):not(.card-header-image) {
    border-radius: 3px;
    margin-top: -20px;
    padding: 15px;
}

.card .card-header .card-title {
    margin-bottom: 3px;
}

table {
    width:100%;
}

table.mat-table {
    width:100%;
    color: #212529;
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%);
    background: #fff;
    width: 100%;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    border: 0;
    margin-top: 30px;
    overflow:hidden !important;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-collapse: collapse;
    border: 0;
    margin-bottom: 30px;
    margin-top: 30px;
    border-radius: 0px;
    /* border-radius: 6px; */
    color: #333;
    background: var(--table-background-color);
    width: 100%;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 1px 4px 0 rgb(0 0 0 / 14%);
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    font-size: 15px;
    border-bottom: 2px solid rgba(0,0,0,.06);
    padding: 0.75rem;
    border-bottom-width: 0px;
}

th.mat-header-cell, td.mat-cell, td.mat-footer-cell {
    padding: 0.75rem !important;
}

th.mat-header-cell {
    font-weight: 400;
}

table thead th {
    font-weight: 500;
    border-top-width: 0;
    border-bottom-width: 1px;
    vertical-align: bottom;
    border-bottom: 2px solid rgba(0,0,0,.06);
    color: #000000!important;
    font-weight: 500;
    border-top-width: 0;
    border-bottom-width: 1px;
}

table thead th {
    vertical-align: bottom;
    border-bottom: 2px solid rgba(0,0,0,.06);
}

table td {
    border-top: none;
    padding: 12px 8px;
    vertical-align: middle;
    font-size: 14px !important;
    font-weight: 300;
}

mat-form-field {
    width:100%;
}

.summary-hero {
    width: 120px;
    height: 120px;
    position: relative;
    border-radius: 50%;
    padding-top: 1em;
    font-size: 36px;
    margin: 0 auto;
}

.summary-hero-appt-details {
    width: 50px;
    height: 33px;
    line-height: 0px;
    position: relative;
    border-radius: 50%;
    padding-top: 20px;
    font-size: 16px;
    margin: 0 auto;
}

/* Card Styling */
/* .mat-dialog-container {

  } */

  .full-width-field {
      width: 100%;
   }

  .form .mat-form-field + .mat-form-field {
      margin-left: 8px;
  }

  .form .mat-form-field + .mat-form-field {
    margin-left: 8px;
  }

  .mat-raised-button {
    margin: 15px 15px 0px 0px;
  }

  /* .form .mat-form-field {

  } */

  .form form {
    margin-bottom: 20px;
  }

  .form form > * {
    margin: 5px 0;
  }

  .form .mat-radio-button {
    margin: 0 12px 2px;
  }

  .form .mat-checkbox {
    margin: 0 11px;
  }

  table {
    width:100%;
  }

  p.summary-hero {
    margin-left: auto;
    margin-right: auto;
}

button.mat-focus-indicator.mat-raised-button.mat-button-base.mat-accent {
    /* text-transform: uppercase !important; */
}

.loading-shade, .shade-locked {
    margin: 0px -24px -68px -24px;
    padding: 0px 24px 68px 24px;
    padding-bottom: 50px;
    top: 0;
    left: 0;
    bottom: 0px;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    overflow: hidden;
}

.mat-dialog-content {
    position: relative;
    min-height: 250px;
}

.matProgressSpinnerCentered, .lockIconCentered {
    position: absolute !important;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    z-index: 1000;
}
.mat-icon.lockIconCentered {
    font-size:50px;
    width: 50px;
    height: 50px;
}
button.mat-cancel,
button.mat-button.mat-cancel {
    background-color: var(--cancel-button-color) !important;
}
button.mat-button-base {
    background-color: unset !important;
    background-color: var(--edit-button-color) !important;
    color: white;
}
button.mat-primary,
button.mat-button.mat-primary {
    background-color: unset !important;
    background-color: var(--save-button-color) !important;
    color: white;
}
button.mat-accent,
button.mat-button.mat-accent {
    background-color: unset !important;
    background-color: var(--bright-accent) !important;
    color: white;
}
button.mat-warn,
button.mat-button.mat-warn {
    background-color: unset !important;
    background-color: var(--warning-color) !important;
    color:black !important;
}
button.mat-primary.mat-stroked-button,
button.mat-accent.mat-stroked-button {
    color: white;
}
button.mat-button-disabled::after {
    content: '';
    width: 100%;
    height: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(255,255,255, 0.3);
}
button.mat-button-disabled::after {
    border-radius: 4px;
}
.mat-fab.mat-button-disabled::after,
.mat-mini-fab.mat-button-disabled::after {
    border-radius: inherit;
}
a.mat-raised-button {
    background-color: var(--raised-button-color);
    color: var(--menu-item-color);
}
a {
    color: unset !important;
    color: var(--cancel-button-color) !important;
}
a.mat-flat-button {
    background: var(--table-header-background)
}

.logoText {
    font-family: var(--accent-font);
    font-weight: 400;
    color: var(--save-button-color);
}
.mat-icon-button.mat-accent {
    color: white;
}
.mat-icon-button.mat-primary {
    color: white;
}
.mat-form-field-appearance-fill .mat-form-field-flex {
    background-color: var(--form-field-background)
}

/* table.mat-table {
    background: var(--save-button-color);
} */
table.mat-table tbody tr:nth-child(even) {
    background: rgba(255,255,255, .35);
}
table.mat-table thead {
    background-color: var(--table-header-background);
    font-family: var(--accent-font);
    font-weight: 700;
}
.mat-expansion-panel,
.mat-expansion-panel-header,
.mat-accordion .mat-expansion-panel:not(.mat-expanded), .mat-accordion .mat-expansion-panel:not(.mat-expansion-panel-spacing) {
    background-color: unset !important;
    /* background-color: var(--expansion-background-color) !important; */ /* Removed because causing issue with the loading shade. */
}
button.mat-button.menu-item {
    background-color: unset !important;
    background-color: none !important;
    color: var(--menu-item-color);
}
h1.page-header {
    font-family: var(--accent-font);
}
.ngx-timepicker {
    margin-bottom: 20px;
}
.ngx-timepicker {
    position: relative;
    max-width: 300px;
    width: 100%;
    height: 36px;
    border-bottom: 1px solid rgba(0,0,0,);
}
.mat-card-actions button:first-child {
    background: unset !important;
    background-color: var(--card-button-button1) !important;
}
.mat-card-actions button:last-child {
    background: unset !important;
    background-color: var(--card-button-button2) !important;
}
button.mat-expand {
    background: unset !important;
    background: var(--card-button-button1) !important;
}
button.mat-collapse {
    background: unset !important;
    background: var(--card-button-button2) !important;
}
/* Does not correspond to any material on the page */
.callout-section {
    background: var(--bright-accent);
    border-radius: 4px;
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);
}
.menu-item {
    color: unset !important;
    color: var(--menu-item-color) !important;
}

/* Just to see all colors together */
.logo-text-color {background: var(--logo-text-color)}
.save-button-color {background: var(--save-button-color);}
.edit-button-color {background: var(--edit-button-color)}
.raised-button-color { background: var(--raised-button-color) }
.cancel-button-color { background: var(--cancel-button-color) }
.bright-accent { background: var(--bright-accent) }
.warning-color { background: var(--warning-color) }
.table-header-background { background: var(--table-header-background) }
.table-background-color { background: var(--table-background-color) }
.toolbar-background { background: var(--toolbar-background) }
.toolbar-text-color { background: var(--toolbar-text-color) }
.card-background-color { background: var(--card-background-color) }
.menu-item-color { background: var(--menu-item-color) }
.expansion-background-color { background: var(--expansion-background-color) }
.form-field-background { background: var(--form-field-background) }
.card-button-button1 { background: var(--card-button-button1) }
.card-button-button2 { background: var(--card-button-button2) }

.mat-expansion-panel {
    margin-bottom: 5px !important;
}
button.mat-menu-item {
    color: #000 !important;
}
.fc .fc-scrollgrid-section-header.fc-scrollgrid-section-sticky>* {
    top: 64px !important;
    background:white;
}

mat-icon.mat-icon.notranslate.me-1.float-end.material-icons.mat-icon-no-color {
    cursor: pointer;
}

.display-edit-field {
    background: rgba(0,0,0,.01); border: 0.5px solid rgba(0,0,0,.2);
}

/* Full Calendar */
.fc-nonbusiness {
    background: #d7d7d7;
}

mat-dialog-content {
  overflow:scroll;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  border-radius: 5px;
  background-color: rgba(0,0,0,.5);
  box-shadow: 0 0 1px rgba(255,255,255,.5);
}

 .eventTabGroup>.mat-tab-header {
     display: none;
 }

.signature-canvas {
    border: 1px solid black;
    display: block;
    touch-action: none;
}

.fc-timegrid-event {
    cursor: pointer;
}

.tippy-box {
    color: #fff;
    border-radius: 4px;
    padding: 3px;
    background: rgba(97, 97, 97, .9);
    /* font-family: Roboto, sans-serif; */
  }

span.fc-col-header-cell-cushion {
    cursor: pointer;
}

#custom-popover, #custom-context-menu {
    position: absolute;
    background: #fff;
    border: 1px solid #bebebe;
    border-radius: 0.2em;
    box-shadow: 0 2px 5px rgb(0 0 0 / 50%);
}

.context-menu-item {
    width:200px;
    padding:4px;
}

#custom-context-menu hr {
    margin:0;
    margin-top:1px;
    margin-bottom:1px;
}

.popover-content {
    padding:5px;
}
.popover-content button:hover, .context-menu-item:hover {
    background:#2980b9;
    color:#fff !important;
}

.context-menu-item:hover * {
    color:#fff !important;
}

#custom-context-menu mat-icon {
    padding: 0px;
    width: 22px;
    height: 20px;
    color: #2980b9;
    font-size: 16px;
    display: inline-block;
    padding-top: 0px;
    vertical-align: middle;
}
#custom-context-menu .context-button {
    padding: 0px;
    width: 100px;
    color: #000;
    display: inline-block;
}

.hide-mat-horizontal-stepper-header-container .mat-horizontal-stepper-header-container {
    display: none;
}

.mat-tab-body-content {
    margin-top: 0;
}

.mat-dialog-container {
    border-radius: 4px !important;
}

.mat-dialog-container .mat-dialog-title {
	border-top-right-radius: 4px;
	border-top-left-radius: 4px;
}

.paymentFormTabs .mat-tab-header {
    display: none;
}

.confirmation-status {
    color: #3ee;text-align:center; font-size:12px; position:absolute; right:2px; top:0;
}

.confirmation-status > .material-icons {
    font-size:18px;
}

.btn-color-status-update {
    background-color: unset !important;
    background-color: #82CFFD !important;
    border-color: #82CFFD !important;
}

button.mat-focus-indicator.confirmAppointment.mat-flat-button.mat-button-base {
    background-color: #fff !important;
    color: black;
    border-color: #144078 !important;
    border: 1px #144078 solid;
}
button.mat-focus-indicator.addLesson.mat-flat-button.mat-button-base {
    background-color: #1DA237 !important;
    border-color: #1DA237 !important;
}

button.mat-focus-indicator.addService.mat-flat-button.mat-button-base {
    background-color: #1DA237 !important;
    border-color: #1DA237 !important;
}

button.mat-focus-indicator.postLesson.mat-flat-button.mat-button-base {
    background-color: #FF7216 !important;
    border-color: #FF7216 !important;
}

button.mat-focus-indicator.studentDashboard.mat-flat-button.mat-button-base {
    background-color: #38B0DE !important;
    border-color: #38B0DE !important;
}

button.mat-focus-indicator.enrollment.mat-flat-button.mat-button-base {
    background-color: #3D59AB !important;
    border-color: #3D59AB !important;
}

button.mat-focus-indicator.payment.mat-flat-button.mat-button-base {
    background-color: #3B3178 !important;
    border-color: #3B3178 !important;
}

button.mat-focus-indicator.editLesson.mat-flat-button.mat-button-base {
    background-color: #0F0F0F !important;
    border-color: #0F0F0F !important;
}

button.mat-focus-indicator.cancelReschedule.mat-flat-button.mat-button-base {
    background-color: #A3A3A3 !important;
    border-color: #A3A3A3 !important;
}

/* .mat-calendar-body-cell {
    -webkit-user-select: none;
    user-select: none;
    cursor: pointer;
    outline: none;
    border: none;
    -webkit-tap-highlight-color: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: none;
    text-align: center;
    outline: none;
    font-family: inherit;
    margin: 0;
  } */

.cursor-pointer:hover {
    cursor: pointer;
}

tbody.ngx-mat-calendar-body {
    height:248px;
}

.stripe_saved_payments span.mat-radio-label-content, .square_saved_payments span.mat-radio-label-content {
    width:100%;
}

.amt-bg-green {
    background-color: #2ECC40 !important;
  }

.amt-bg-blue {
    background-color: #0074D9 !important;
}

.amt-bg-orange {
    background-color: #FF851B !important;
}

.amt-bg-yellow {
    background-color: #FFaa00 !important;
}

.amt-bg-purple {
    background-color: #B10DC9 !important;
}

.amt-bg-red {
    background-color:#FF4136 !important;
}

.amt-bg-gray {
    background-color:#AAAAAA !important;
}


.mat-icon-animate-spin .mat-icon {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    100% { transform: rotate(360deg); }
}

.vertical-tab-labels a.nav-link {
    cursor: pointer;
    margin-bottom: 5px; /* Spacing between tabs */
}

.vertical-tab-labels a.nav-link.active {
    color: #fff;
    background-color: #007bff; /* Active tab color */
}

.dropped-enrollment {
    background-color: #ffcccc !important; /* Light red */
}

.configuration-card {
    padding: 0px !important;
    border-radius: 4px !important;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    background:#E8F2FF;
}
.configuration-card .mat-card-header {
    padding: 10px;
    /* margin-bottom: 10px; */
    background-color: #A6CCFF !important;
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
.configuration-card .mat-card-header .mat-card-title {
    margin-bottom: 0px;
    font-size: .9375rem !important;
    font-weight: 600;
    line-height: 1.5rem;
}


.configuration-card .mat-card-content {
    padding: 10px;
}

.configuration-card mat-card-actions.mat-card-actions.mat-card-actions-align-end {
    padding-right:20px;
    padding-bottom:10px;
}

.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
}

.custom-toggler.navbar-toggler {
    border-color: rgb(255, 255, 255);
}

nav.navbar.navbar-expand-lg.menu-toolbar-mat {
    min-height: 64px;
    padding: 0 16px;
    --toolbar-background: #215DAB;
}

nav.navbar.navbar-expand-lg {
    background: #215DAB !important;
    height: 64px;;
}

.collapse.navbar-collapse.show {
    padding-top:16px;
}

.custom-navbar {
}

.mat-option-text {
    font-size: 13px;
}

.mat-dialog-container .no-border {
    padding: 0 !important;
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
