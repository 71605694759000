/* Move some of this. */
app-menu {
	position: fixed;
	width: 100%;
	z-index: 100;
}
.page-container {
	padding: 80px 16px 16px;
}
h1.page-header {
	font-size: 36px;
	text-align: center;
	padding-top:88px;
	padding-bottom:15px;
	/* padding: 22px; */
	/* margin:  -16px -16px 8px; */
}

.fc-timegrid-event-harness>.fc-timegrid-event {
	overflow: hidden;
}

full-calendar.fc .fc-timegrid-now-indicator-arrow {
	border-color: #286090;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
full-calendar.fc .fc-timegrid-now-indicator-line {
	border-color: #286090;
}
full-calendar.fc .fc-timegrid-col.fc-day-today,
full-calendar.fc .fc-daygrid-day.fc-day-today {
	background-color: #fff;
}

.mat-dialog-container .mat-dialog-title {
	margin: -24px -24px 0;
	padding: 16px 24px;
	/*background: #eee;*/
	background: #286090;
	color: #fff;
  border-bottom: 1px solid #ddd;
	display: flex;
	flex-direction: row;
	flex: 1;
}
.mat-dialog-title h1 {
	display: flex;
	flex: 1;
	margin: 0;
	line-height: 38px;
}
.mat-dialog-title button {
	display: flex;
	min-width: 0;
  padding: 0 8px;
  height: 38px;
}
mat-dialog-container .mat-dialog-content {
	/*overflow: visible;*/
	padding-top: 16px;
}
mat-dialog-container .mat-dialog-actions .mat-raised-button {
  margin: 0;
}

.mat-expansion-panel-header mat-panel-description {
  flex-grow: 10;
}

.event_title {
	color:#fff;
	text-align: center;
	/* text-align:left;
	padding-left:3px; */
}

.notes-icon>.material-icons {
	font-size:1px;
}

.confirmation-status, .notes-icon {
	color: #3ee;text-align:center; font-size:12px; position:absolute;
}

.confirmation-status {
	right:2px; top:0;
}

.notes-icon {
	right:2px;
	bottom: 0;
}

.confirmation-status > .material-icons, .notes-icon > .material-icons {
	font-size:18px;
}
